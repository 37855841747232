
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import DetailContrat from "@/views/still/contrats/DetailContrat.vue";
import ImportContrat from "@/views/still/contrats/ImportContrat.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    DetailContrat,
    ImportContrat,
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      contratSelect: {},
    });

    const tableHeader = ref([
      {
        name: "Contrat",
        key: "fsh_contract_number",
        sortable: true,
      },
      {
        name: "Type",
        key: "loc_nature",
        sortable: true,
      },
      {
        name: "Libellé du contrat",
        key: "cont_libelle",
        sortable: true,
      },
      {
        name: "Période",
        key: "fsh_date_debut",
        sortable: true,
      },
      /*
      {
        name: "SmartAccount",
        key: "cnt_smart_account",
        sortable: true,
      },
      */
      {
        name: "Commandes",
        key: "xliens",
        sortable: true,
      },
      {
        name: "Actions",
        key: "code",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Constructeur",
        key: "constructeur",
        label: "constructeur",
      },
      {
        name: "Type",
        key: "loc_nature",
        label: "loc_nature",
      },
    ]);

    const ExcelFormat = ref({
      "Contrat": "fsh_contract_number",
      "Type": "loc_nature",  
      "Libellé du contrat": "cont_libelle",
      "Début": "fsh_date_debut",
      "Fin": "fsh_date_fin",
      "Commandes": "xlienssmart",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const passLng = (d) => {
      state.myList.forEach(
        function(item: any){
          if (item.displayrow == 1) {
            item.displayrow = 2
          } else {
            item.displayrow = 1
          }
        }
      );
    }

    const changeLabel = async (cont) => {
      mAxiosApi
        .post("updateContLabel", cont)
        .then(({ data }) => {
          // console.log(data["results"]);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const affDetail = async (data) => {
      // console.log('Detail',data);
      state.contratSelect = data;
      state.drawer = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.contratSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.contratSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.contratSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const changeContrat = (ev) => {
      // console.log('Passe ici ', ev);

      const index = state.myList.findIndex(
        (object: any) => object.code === ev.code
      );
      const myList: any = state.myList;
      myList[index]['cont_x_archive'] = ev.cont_x_archive;
      // console.log('INDEX', index);

    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion des Contrats de support & Abonnements", []);

      let myList = await mAxiosApi.prototype.getAxios("/getContratSupport");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      console.log(state.myList);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      imgConst,
      passLng,
      changeLabel,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      changeContrat,
    };
  },
});
